import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import Heading from '../components/Styles/Heading';
import Text from '../components/Styles/Text';
import Flex from '../components/Styles/Flex';
import Box from '../components/Styles/Box';
import Card from '../components/Styles/Card';
import UserAstronautIcon from '../components/Icons/Fa/Thin/UserAstronautIcon';
import UserCrownIcon from '../components/Icons/Fa/Thin/UserCrownIcon';
import ChartUserIcon from '../components/Icons/Fa/Thin/ChartUserIcon';

const CompanyPage = ({ location, data }) => (
  <Layout location={location}>
    <SEO title="About Us" />

    <PageHeaderStyled
      heading="A little bit about us"
      subHeading="Founded in 2016 in Boston by Patrick Koulalis, ALTdev was formed to provide professional web-based services to businesses of all sizes, helping them reach their goals. We offer a wide range of design and development services while using the latest technologies. With over 15+ years of experience in web development, Our experts are ready for anything that comes our way."
      arrow="true"
      buttonText="Let's chat about your project"
      buttonLink="/contact/"
    />

    <SectionStyled topDivider={true}>
      <Flex flexWrap="wrap">
        <BoxLeft flex="0 0 50%">
          <Text as="h4">What we do</Text>
          <Heading>Committed to transforming your website</Heading>
          <Text>
            Our mission is to provide our customers with the tools to
            effectively present and market themselves online, creating value for
            you and your stakeholders. We strive to help build your brand,
            improve your global web presence, and develop more meaningful
            relationships with your customers.
          </Text>
          <Text>
            ALTdev is one of the top web development companies that uses our
            knowledge, skills, and technological expertise to deliver
            world-class development services. We can deliver strategically
            designed and creatively crafted websites and web apps to take your
            business to the next level.
          </Text>
        </BoxLeft>
        <BoxRight flex="0 0 50%">
          <Heading as="h4">Our services</Heading>
          <Link to="/services/web-design">Web Design</Link>
          <Link to="/services/managed-web-hosting/">Managed Web Hosting</Link>
          <Link to="/services/support-maintenance/">
            Support and Maintenance
          </Link>
          <Link to="/services/white-label-websites/">White Label Websites</Link>
          <div className="link-wrap">
            <span>Web Development</span>
          </div>
          <div className="link-wrap">
            <span>UI/UX design</span>
          </div>
          <div className="link-wrap">
            <span>Information Architecture</span>
          </div>
          <div className="link-wrap">
            <span>PSD to HTML (HTML5, CSS3, JS)</span>
          </div>
        </BoxRight>
      </Flex>
    </SectionStyled>

    <SectionGradient bottomDivider={true}>
      <Card boxShadow={true}>
        <Flex flexWrap="wrap">
          <Box flex="0 0 100px" className="icon-wrap">
            <UserAstronautIcon />
          </Box>
          <Box flex="1" className="icon-content">
            <Heading>Transparency</Heading>
            <Text>
              Our aim is to ensure complete transparency throughout the entire
              project. From the day you request a quote all the way up to the
              day we launch your new website. We make our proposals easy to
              understand, with no hidden costs so you can see exactly what
              you’re getting. We make sure you are involved throughout the
              entire process from the design, development, populating, and
              launch of your new site.
            </Text>
          </Box>
        </Flex>
      </Card>
      <Card boxShadow={true}>
        <Flex flexWrap="wrap">
          <Box flex="0 0 100px" className="icon-wrap">
            <UserCrownIcon />
          </Box>
          <Box flex="1" className="icon-content">
            <Heading>Collaboration</Heading>
            <Text>
              To make sure we keep things 100% transparent our online project
              management tool allows you to see the breakdown of your project in
              easy to understand chunks. An account manager will be with you
              every step of the way and will walk you through what we need from
              you, when we need it, what we’re working on and when each
              milestone will be completed.
            </Text>
          </Box>
        </Flex>
      </Card>
      <Card boxShadow={true}>
        <Flex flexWrap="wrap">
          <Box flex="0 1 100px" className="icon-wrap">
            <ChartUserIcon />
          </Box>
          <Box flex="1" className="icon-content">
            <Heading>Results</Heading>
            <Text>
              Our expert team of web designers and developers will make sure
              that your website looks great and works great. However, just
              because it looks good does not mean it reaches your business
              goals. That&apos;s the reason we ask “what is the goal of your new
              website?” at the start of every discovery meeting. Our team is
              well versed in sales, lead generation, UI/UX Design, information
              architecture, allowing us to create a user experience for your
              customer that delivers results.
            </Text>
          </Box>
        </Flex>
      </Card>
    </SectionGradient>
  </Layout>
);

const PageHeaderStyled = styled(PageHeader)`
  min-height: 35vh;
`;

const SectionStyled = styled(Section)`
  background: var(--lightBlue);
  /* color: white; */
  position: relative;
`;

const SectionGradient = styled(Section)`
  background: var(--secondary);
  ${Card} {
    margin-bottom: 1em;
  }
  .icon-wrap {
    margin-right: 2em;
  }
  @media (max-width: 960px) {
    .icon-content {
      flex: 0 0 100%;
    }
  }
`;

const BoxLeft = styled(Box)`
  h4 {
    font-weight: 200;
  }
  @media (max-width: 960px) {
    flex: 0 0 100%;
    h2 {
      font-size: 3em;
    }
  }
`;

const BoxRight = styled(Box)`
  padding: 64px 0 0 15%;
  .link-wrap {
    margin-bottom: 8px;
  }
  a {
    display: block;
    margin-bottom: 8px;
    color: var(--primary);
  }
  @media (max-width: 960px) {
    padding: 0;
    flex: 0 0 100%;
  }
`;

CompanyPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default CompanyPage;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const tabData = [
  {
    label: 'Discovery',
    icon: '1',
    content: {
      heading: 'Discovery',
      content:
        'We give the utmost importance to understand and document client’s inputs, design, and branding preferences. And clarify all the open-ended points to establish a precise and clear idea about both clients’ and project’s goals. We work in a collaborative approach involving key stakeholders to bring alignment in the business process.',
    },
  },
  {
    label: 'Planning',
    icon: '2',
    content: {
      heading: 'Planning',
      content:
        'Our services emphasize on well-defined project planning and leave nothing for assumption to avoid miscommunication. We jot down how we are going to proceed further, define detailed technical specifications, devise the design & branding guidelines of the project, and get confirmation and approval from the clients’ end on the documents and materials.',
    },
  },
  {
    label: 'Design',
    icon: '3',
    content: {
      heading: 'Design',
      content:
        'We provide clients with Web, Tablet, and Mobile-specific UI designs of the projects based on the approved wireframes and design guidelines. As a trusted entity, we deliver clickable prototypes on Invision platform and the source files in either Photoshop or Sketch format. Getting system UI designs approved and confirmed by the client is one of our objectives at this stage.',
    },
  },
  {
    label: 'Development',
    icon: '4',
    content: {
      heading: 'Development',
      content:
        'At this stage, we cater requirements for frontend, backend, web services, and API development integration. Along with preparing a strategy for Agile Scrum methodology, we factor the aspects of scalability, multi-tenancy, 3rd party integration, and craft an optimized clean code structure using cutting-edge technologies. We make sure to involve and implement clients’ reviews in each sprint/milestone.',
    },
  },
  {
    label: 'Testing',
    icon: '5',
    content: {
      heading: 'Testing',
      content:
        'We test each sprint manually, report bugs and add those to product backlog. Once the bug fixing is done, we provide the quality release and then the final demo of the sprint is sent for clients’ approval. We make sure to do regression testing to ensure proper functioning of the previously approved milestones/sprints.',
    },
  },
  {
    label: 'Maintenance',
    icon: '6',
    content: {
      heading: 'Maintenance',
      content:
        'Our work doesn’t end with development, we go the extra mile for our clients. Through an Agile approach, we ensure product enhancement. We do regular Security Audits, Bi-weekly code backups, and constant system upgradation. Along with testing the entire system each month to find any incompatibility or error, we monitor the traffic and server load and accordingly optimize it.',
    },
  },
];
