
		import React from 'react';

		function UserAstronautIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 448, 512">
					<path d="M349.2 308.8c-3.01-1.551-6.711-.9043-9.105 1.49c-3.711 3.709-2.824 10.01 1.84 12.41c54.6 28.11 90.25 85.07 90.04 147.3C431.9 484.4 419.8 496 405.3 496H336V448c0-26.51-21.49-48-48-48H160c-26.51 0-48 21.49-48 48v48H42.66c-14.49 0-26.61-11.6-26.65-26.1c-.2148-62.18 35.43-119.1 90.04-147.2c4.664-2.402 5.553-8.697 1.842-12.41C105.5 307.9 101.8 307.2 98.78 308.8C39.01 339.6-.1738 401.7 0 469.8C.0605 493.2 19.29 512 42.66 512H120C124.4 512 128 508.4 128 504V448c0-17.6 14.4-32 32-32h128c17.6 0 32 14.4 32 32v56c0 4.418 3.582 8 8 8h77.34C428.7 512 447.9 493.2 448 469.8C448.2 401.7 409 339.6 349.2 308.8zM184 448c-4.438 0-8 3.562-8 8v48C176 508.4 179.6 512 184 512S192 508.4 192 504v-48C192 451.6 188.4 448 184 448zM272 448c-8.875 0-16 7.125-16 16s7.125 16 16 16s16-7.125 16-16S280.9 448 272 448zM152 168h16v16C168 188.4 171.6 192 176 192s8-3.594 8-8v-16h16C204.4 168 208 164.4 208 160S204.4 152 200 152h-16v-16C184 131.6 180.4 128 176 128S168 131.6 168 136v16h-16C147.6 152 144 155.6 144 160S147.6 168 152 168zM408 224C412.4 224 416 220.4 416 216v-112C416 99.59 412.4 96 408 96S400 99.59 400 104v112C400 220.4 403.6 224 408 224zM200 256h48c53 0 96-43 96-96V136c0-22.12-21.5-40-48-40h-144c-26.5 0-48 17.88-48 40V160C104 213 147 256 200 256zM120 136c0-13.01 14.65-24 32-24h144c17.35 0 32 10.99 32 24V160c0 44.11-35.89 80-80 80h-48c-44.11 0-80-35.89-80-80V136zM40 224C44.41 224 48 220.4 48 216v-112C48 99.59 44.41 96 40 96S32 99.59 32 104v112C32 220.4 35.59 224 40 224zM224 320c88.37 0 160-71.63 160-160s-71.63-160-160-160S64 71.63 64 160S135.6 320 224 320zM224 16c79.4 0 144 64.6 144 144S303.4 304 224 304S80 239.4 80 160S144.6 16 224 16z" />
				</svg>
			);
		}

		export default UserAstronautIcon;
	